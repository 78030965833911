import { MainBody } from './MainBody';
import './HomeBody.css';
import { IcoTitleNoticias } from '../../../images';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NoticiasBody } from './NoticiasBody';

export const HomeBody: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <MainBody pageClassName='HomeClass'>
        {/* Begin | Notícias */}
        <Container className='container-noticias'>
          <Row>
            <Col>
              <br />
              <h2>
                <img src={IcoTitleNoticias} alt='' />
                <span>{t('news')}</span>
              </h2>
            </Col>
          </Row>

          <NoticiasBody fromHomePage={true} />
        </Container>
        {/* End | Notícias */}
      </MainBody>
    </>
  );
};
