import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enJson from './translations/en.json';
import esJson from './translations/es.json';
import ptBrJson from './translations/ptBr.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18n.use(initReactI18next).init({
  fallbackLng: 'ptBR',
  returnNull:false,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    es: esJson,
    en: enJson,
    ptBR: ptBrJson,
  },
});

export default i18n;
