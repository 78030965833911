/* eslint-disable import/no-anonymous-default-export */
import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { IListParams, sessionCatalogoService } from '../services/api/catalogo/CatalogoService'
import { sessionListaCatalogoStore } from '../components/store'
import i18n from '../../i18n'

export default () => {
  const { getAll, getFiltered } = sessionCatalogoService()

  const [sessionListaCatalogo, setSessionListaCatalogo] = useRecoilState(sessionListaCatalogoStore)

  // Parâmetros para a função getListParams
  interface IGetListParams {
    cBuscaGeral?: string
    cCdWeb?: string
    cCdOriginal?: string
    cCdSimilares?: string
    cLinha?: string
    cFamilia?: string
    cMarca?: string
    cModelo?: string
    cLancto?: string
    cIdioma?: string
  }

  // Função para obter listParams com parâmetros opcionais
  const getListParams = (params: IGetListParams): IListParams => {
    const language = i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase()
    return {
      cBuscaGeral: params.cBuscaGeral || '',
      cCdWeb: params.cCdWeb || '',
      cCdOriginal: params.cCdOriginal || '',
      cCdSimilares: params.cCdSimilares || '',
      cLinha: params.cLinha || '',
      cFamilia: params.cFamilia || '',
      cMarca: params.cMarca || '',
      cModelo: params.cModelo || '',
      cLancto: params.cLancto || '', // SIM = Valor padrão para buscas por lançamentos
      cIdioma: params.cIdioma || language
    }
  }

  const handleCatalogo = useCallback(() => {
    let lancamento: string = 'SIM'
    const listParams = getListParams({ cLancto: lancamento })
    getAll(listParams).then((result) => {
      if (result instanceof Error) {
        alert(result.message)
        return
      }
      return setSessionListaCatalogo(result)
    })
  }, [getAll, setSessionListaCatalogo])

  const handleSearchCatalogo = useCallback(
    async (cdWeb: string) => {
      const listParams: IListParams = {
        cBuscaGeral: '',
        cCdWeb: cdWeb,
        cCdOriginal: '',
        cCdSimilares: '',
        cLinha: '',
        cFamilia: '',
        cMarca: '',
        cModelo: '',
        cLancto: '',
        cIdioma: i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase() // Define o idioma
      }
      await getFiltered(listParams).then((result) => {
        if (result instanceof Error) {
          alert(result.message)
          return
        }

        setSessionListaCatalogo(result)
      })
    },
    [getFiltered, setSessionListaCatalogo]
  )

  return {
    sessionListaCatalogo,
    setSessionListaCatalogo,
    handleCatalogo,
    handleSearchCatalogo
  }
}
