import React from 'react';
import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';

import './i18n/index';
import { App } from './App';
import { RecoilRoot } from 'recoil';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </I18nextProvider>
  </React.StrictMode>
);
