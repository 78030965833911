import { useTranslation } from 'react-i18next';
import { IcoNavContato } from '../../images';
import { MainFooter, MainHeader, MainLinhas, ContatoBody, MainFiltro, MainBreadcrumbs } from '../../shared/components';

export const Contato: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <MainHeader />
      <MainFiltro />
      <MainBreadcrumbs title={t('contact')} iconBreadCrumbs={IcoNavContato} />
      <ContatoBody />
      <MainLinhas />
      <MainFooter />
    </>
  );
};
