import { Col, Container, Row } from 'react-bootstrap';
import './MainBreadcrumbs.css';

export interface IBreadCrumbsProps {
  title: string;
  iconBreadCrumbs: string;
}

export const MainBreadcrumbs: React.FC<IBreadCrumbsProps> = ({ title, iconBreadCrumbs }) => {
  return (
    <>
      <Container className='BreadcrumbsClass'>
        <Row>
          <Col className='df'>
            <h2>
              <img src={iconBreadCrumbs} alt='' />
              <span>{title}</span>
            </h2>
          </Col>
        </Row>
      </Container>
    </>
  );
};
