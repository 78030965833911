import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

import { LogoFooter } from '../../../images';
import { ListItemLink } from '../generic/ListItemLink';
import './MainFooter.css';
import { LinksExternos } from '../../utils/constants';
import { faFacebookF, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';

export const MainFooter: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Begin | FooterClass */}
      <section className='FooterClass'>
        <Container>
          <Row>
            <Col className='coluna-logo'>
              <ul>
                <ListItemLink to='/home/#' icon={LogoFooter} className='navbar-brand' />
              </ul>
            </Col>
          </Row>
          <Row>
            <Col className='coluna-1' xxl='6' xl='6' lg='6' md='12' sm='12'>
              <div className='list-navegacao'>
                <ul className='MenuFooter'>
                  <li className='Negrito'>{t('footerNavegation')}</li>
                  <ListItemLink to='/home' label={t('home')} />
                  <ListItemLink to='/institucional' label={t('aboutUs')} />
                  <ListItemLink to='/qualidade' label={t('quality')} />
                  <ListItemLink to='/catalogo' label={t('catalog')} />
                  <ListItemLink to='/representantes' label={t('representative')} />
                  <ListItemLink to='/noticias' label={t('news')} />
                  <ListItemLink to='/contato' label={t('contact')} />
                </ul>
              </div>
              <div className='list-servicos'>
                <ul className='MenuFooter'>
                  <li className='Negrito'>Serviços</li>
                  <li>
                    <a href={LinksExternos.LINKWEBPEDIDOS2} target='_blank' rel='noreferrer'>
                      {t('productOrders')}
                    </a>
                  </li>
                  <li>
                    <a href={LinksExternos.LINKWEBMAIL} target='_blank' rel='noreferrer'>
                      {t('webMail')}
                    </a>
                  </li>
                  <li>{t('system')}</li>
                </ul>
              </div>
            </Col>
            <Col className='coluna-2 ta-right' xxl='6' xl='6' lg='6' md='12' sm='12'>
              <div className='endereco-comercial'>
                <h3>{t('footerCommercialAddress')}</h3>
                <address>Rua Serra de Botucatu, 660 Cj 51 Tatuapé/SP</address>
                <p>
                  <FontAwesomeIcon icon={faPhone} />
                  <span>+55 11 2644-0406</span>
                </p>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span className='box-email'>
                    {t('footerSalesTeam')}:&nbsp;
                    <a href='mailto:escritorio@marilia-sa.com.br'>escritorio@marilia-sa.com.br</a>
                  </span>
                </p>
              </div>
              <div className='endereco-fabrica'>
                <h3>{t('footerManufactoryAddress')}</h3>
                <address>Rua Jose Campanella, 70 Guarulhos/SP</address>
                <p>
                  <FontAwesomeIcon icon={faPhone} />
                  <span>+55 (11) 2842-6100</span>
                </p>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span className='box-email'>
                    <a href='mailto:marketing@marilia-sa.com.br'>marketing@marilia-sa.com.br</a>
                  </span>
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className='col-socialmedia'>
              <ul className='list-socialmedia'>
                <li>
                  <a href={LinksExternos.LINKFACEBOOK} target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
                <li>
                  <a href={LinksExternos.LINKINSTRAGRAM} target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
                <li>
                  <a href={LinksExternos.LINKLINKEDIN} target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
                <li>
                  <a href={LinksExternos.LINKYOUTUBE} target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      {/* End | FooterClass */}

      {/* Begin | FooterBottomClass */}
      <section className='FooterBottomClass'>
        <Container>
          <Row>
            <Col>2018 © Indústria Marília SA</Col>
          </Row>
        </Container>
      </section>
      {/* Begin | FooterBottomClass */}
    </>
  );
};
