import { BrowserRouter as Router } from 'react-router-dom'
import { AppRoutes } from './routes'
import { ToastProvider } from './shared/hooks/ToastContext'

export const App = () => {
  return (
    <ToastProvider>
      <Router>
        <AppRoutes />
      </Router>
    </ToastProvider>
  )
}
