import { ISelectOptions } from '../Interfaces/SelectOptions';

const ufs = [
  { id: 1, value: 'ac', label: 'Acre' },
  { id: 2, value: 'al', label: 'Alagoas' },
  { id: 3, value: 'ap', label: 'Amapá' },
  { id: 4, value: 'am', label: 'Amazonas' },
  { id: 5, value: 'ba', label: 'Bahia' },
  { id: 6, value: 'ce', label: 'Ceará' },
  { id: 7, value: 'DF', label: 'Distrito Federal' },
  { id: 8, value: 'es', label: 'Espírito Santo' },
  { id: 9, value: 'go', label: 'Goiás' },
  { id: 10, value: 'ma', label: 'Maranhão' },
  { id: 11, value: 'mt', label: 'Mato Grosso' },
  { id: 12, value: 'ms', label: 'Mato Grosso do Sul' },
  { id: 13, value: 'mg', label: 'Minas Gerais' },
  { id: 14, value: 'pa', label: 'Pará' },
  { id: 15, value: 'pb', label: 'Paraíba' },
  { id: 16, value: 'pr', label: 'Paraná' },
  { id: 17, value: 'pe', label: 'Pernambuco' },
  { id: 18, value: 'pi', label: 'Piauí' },
  { id: 19, value: 'rj', label: 'Rio de Janeiro' },
  { id: 20, value: 'rn', label: 'Rio Grande do Norte' },
  { id: 21, value: 'rs', label: 'Rio Grande do Sul' },
  { id: 22, value: 'ro', label: 'Rondônia' },
  { id: 23, value: 'rr', label: 'Roraima' },
  { id: 24, value: 'sc', label: 'Santa Catarina' },
  { id: 25, value: 'sp', label: 'São Paulo' },
  { id: 26, value: 'se', label: 'Sergipe' },
  { id: 27, value: 'to', label: 'Tocantins' },
];

const getUf = async (): Promise<ISelectOptions[] | Error> => {
  try {
    return ufs;
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao listar Unidades Federativas');
  }
};

export const sessionUfService = () => ({
  getUf,
});
