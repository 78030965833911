import { Environment } from '../../../enviroments'
import { Api } from '../axios-config'

export interface IFamilyOptions {
  cdFAMILIA: string
  dsFAMILIA: string
  dsFamilia_ESP: string
  dsFamilia_ING: string
}
type TFamilia = {
  ttFAMILIA: IFamilyOptions[]
}

const getAllFamilias = async (language?: string): Promise<IFamilyOptions[] | Error> => {
  try {
    const urlRelativa = `${Environment.COMBO_BASE_URL}&InternalProc=Familia`

    const { data } = await Api.get<TFamilia>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    })

    return data.ttFAMILIA
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao listar as famílias dos produtos')
  }
}

const getFamiliasByCdLine = async (dsLinha: String, language: String): Promise<IFamilyOptions[] | Error> => {
  try {
    const urlRelativa = `${Environment.COMBO_BASE_URL}${dsLinha}&InternalProc=Familia`

    const { data } = await Api.get<TFamilia>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    })

    return data.ttFAMILIA
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao consultar os representantes')
  }
}

export const sessionFamiliaService = () => ({
  getAllFamilias,
  getFamiliasByCdLine
})
