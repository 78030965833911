import './MainHeader.css'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Col, Container, Form, Nav, Navbar, Row } from 'react-bootstrap'

// images
import {
  IcoNavCatalogo,
  IcoNavContato,
  IcoNavDownload,
  IcoNavInicio,
  IcoNavInstitucional,
  IcoNavNoticias,
  IcoNavQualidade,
  IcoNavRepresentantes,
  LogoHeader
} from '../../../images/index'

import { faEnvelope, faMagnifyingGlass, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'

import UseSessionCatalogo from '../../hooks/UseSessionCatalogo'
import { useToast } from '../../hooks/ToastContext'

import { ListItemLink } from '../generic/ListItemLink'
import { LinksExternos } from '../../utils/constants'
import { LanguageSwitcher } from './LanguageSwitcher'

import { IListParams, sessionCatalogoService } from '../../services/api/catalogo/CatalogoService'
import { IListCatalogo } from '../../services/api/Interfaces'

import i18n from '../../../i18n'

export const MainHeader: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { getGeneralSearch } = sessionCatalogoService()
  const { setSessionListaCatalogo } = UseSessionCatalogo()

  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({ querySearch: '' })
  const { setShowToast } = useToast()

  const handleHome = () => {
    navigate('/home')
  }

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const LoadSessionListaCatalogo = (response: IListCatalogo[] | ((currVal: IListCatalogo[]) => IListCatalogo[])) => {
    setSessionListaCatalogo([])
    setSessionListaCatalogo(response)
    navigate('/catalogo')
  }

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setIsLoading(true)

    const listParams: IListParams = {
      cBuscaGeral: formData.querySearch,
      cCdWeb: '',
      cCdOriginal: '',
      cCdSimilares: '',
      cLinha: '',
      cFamilia: '',
      cMarca: '',
      cModelo: '',
      cLancto: '',
      cIdioma: i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase() // Define o idioma
    }

    getGeneralSearch(listParams).then((response) => {
      if (response instanceof Error) {
        alert(response.message)
        return
      } else if (response.length === 0) {
        setShowToast(true) // Exibir o toast se não houver registros
        setIsLoading(false)
      } else {
        LoadSessionListaCatalogo(response)
        setIsLoading(false)
      }
    })
  }

  return (
    <>
      <section className='TopHeaderClass PosFixed'>
        <Container>
          <Row>
            <Col className='coluna-esquerda' xxl='6' xl='3' lg='3' md='3'>
              <ul>
                <li>
                  <a href={LinksExternos.LINKFACEBOOK} target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
                <li>
                  <a href={LinksExternos.LINKINSTRAGRAM} target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
                <li>
                  <a href={LinksExternos.LINKLINKEDIN} target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
                <li>
                  <a href={LinksExternos.LINKYOUTUBE} target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </li>
              </ul>
            </Col>
            <Col className='coluna-direita' xxl='6' xl='9' lg='9' md='9'>
              <ul className='lista-sm-direita'>
                <li>
                  <a href={LinksExternos.LINKFACEBOOK} target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
                <li>
                  <a href={LinksExternos.LINKINSTRAGRAM} target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
                <li>
                  <a href={LinksExternos.LINKLINKEDIN} target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
                <li>
                  <a href={LinksExternos.LINKYOUTUBE} target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </li>
              </ul>
              <ul className='ListContact'>
                <li className='phone'>
                  <FontAwesomeIcon icon={faPhone} />
                  &nbsp;&nbsp;+55 11 2842-6100
                </li>
                <li className='email'>
                  <a href='mailto:faleconosco@marilia-sa.com.br'>
                    <FontAwesomeIcon icon={faEnvelope} />
                    &nbsp;&nbsp;faleconosco@marilia-sa.com.br
                  </a>
                </li>
                <li className='ListServices'>
                  <LanguageSwitcher />
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <section className='HeaderClass PosFixed'>
        <Container>
          <Row>
            <Col className='col-logo' xxl='3' xl='3' lg='3' md='6'>
              <a href='#' className='navbar-brand'>
                <img src={LogoHeader} alt='' onClick={handleHome} />
              </a>
            </Col>
            <Col className='col-pesquisa' xxl='6' xl='6' lg='5' md='6'>
              <Form className='FormSearch' role='search' onSubmit={handleSubmit}>
                <Form.Group className='BoxSearch mb-4' controlId='formBasicText'>
                  <Form.Control
                    type='text'
                    placeholder={t('formSearch')}
                    value={formData.querySearch}
                    onChange={handleInputChange}
                    name='querySearch'
                  />

                  {isLoading ? (
                    <Button variant='btn IcoSearch' type='submit'>
                      <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>{' '}
                      {t('loading')}
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Button>
                  ) : (
                    <Button variant='btn IcoSearch' type='submit'>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Button>
                  )}
                </Form.Group>
              </Form>
            </Col>
            <Col className='col-servicos' xxl='3' xl='3' lg='4' md='6'>
              <ul className='ListServices'>
                <li>
                  <a href={LinksExternos.LINKWEBPEDIDOS2} target='_blank' rel='noreferrer'>
                    {t('productOrders')}
                  </a>
                </li>
                <li>|</li>
                <li>
                  <a href={LinksExternos.LINKWEBMAIL} target='_blank' rel='noreferrer'>
                    {t('webMail')}
                  </a>
                </li>
                <li>|</li>
                <li>
                  <a href=''>{t('system')}</a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      {/* End | BoxHeader */}
      {/* Begin | Navbar */}
      <section className='BoxNavbar PosFixed'>
        <Container>
          <Row>
            <Col className='NavDefault df' xxl='9' xl='9' lg='9' md='12'>
              <Navbar expand='lg'>
                <Container>
                  <Navbar.Toggle aria-controls='basic-navbar-nav' />
                  <Navbar.Collapse id='basic-navbar-nav'>
                    <Nav className='me-auto navbar navbar-expand-lg bg-body-tertiary'>
                      <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                        <ListItemLink to='home' icon={IcoNavInicio} label={t('home')} />
                        <ListItemLink to='institucional' icon={IcoNavInstitucional} label={t('aboutUs')} />
                        <ListItemLink to='qualidade' icon={IcoNavQualidade} label={t('quality')} />
                        <ListItemLink to='noticias' icon={IcoNavNoticias} label={t('news')} />
                        <ListItemLink to='catalogo' icon={IcoNavCatalogo} label={t('catalog')} />
                        <ListItemLink to='representantes' icon={IcoNavRepresentantes} label={t('representative')} />
                        <ListItemLink to='contato' icon={IcoNavContato} label={t('contact')} />
                        <li className='nav-item donwload-pdf'>
                          <img className='IcoNavMenu' src={IcoNavDownload} alt='' />
                          <a className='nav-link nav-link' href=''>
                            Download PDF
                          </a>
                        </li>
                      </ul>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </Col>
            <Col className='NavCatalogo' xxl='3' xl='3' lg='3' md='12'>
              <div className='BoxListServices'>
                <ul className='ListServices'>
                  <li>
                    <a href={LinksExternos.LINKWEBPEDIDOS2} target='_blank' rel='noreferrer'>
                      {t('productOrders')}
                    </a>
                  </li>
                  <li>|</li>
                  <li>
                    <a href={LinksExternos.LINKWEBMAIL} target='_blank' rel='noreferrer'>
                      {t('webMail')}
                    </a>
                  </li>
                  <li>|</li>
                  <li>
                    <a href=''>{t('system')}</a>
                  </li>
                </ul>
              </div>
              <div className='box-downloads'>
                <p>
                  <span>{t('downloadCatalog')}</span>
                  <img className='IcoNavMenu' src={IcoNavDownload} alt='' />
                </p>
              </div>
              <ul className='list-downloads'>
                {/* <li>
                  <a href=''>Desktop</a>
                </li> */}
                <li>
                  {
                    <a
                      href={
                        i18n.language === 'es'
                          ? 'https://www.marilia-sa.com.br/Imagens/Catalogos/CatalogoES.pdf'
                          : i18n.language === 'en'
                          ? 'https://www.marilia-sa.com.br/Imagens/Catalogos/CatalogoEN.pdf'
                          : 'https://www.marilia-sa.com.br/Imagens/Catalogos/CatalogoPT.pdf'
                      }
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      PDF
                    </a>
                  }
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      {/* End | Navbar */}
    </>
  )
}
