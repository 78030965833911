import { Environment } from '../../../enviroments'
import { Api } from '../axios-config'

export interface IModeloOptions {
  cdMODELO: string
  dsMODELO: string
}
type TModelo = {
  ttMODELO: IModeloOptions[]
}

const getModelosByCdLineCdMontadora = async (
  dsLinha?: String | '',
  dsFamilia?: string | '',
  dsMontadora?: string | ''
): Promise<IModeloOptions[] | Error> => {
  try {
    let listParam = `${dsLinha},${dsFamilia},${dsMontadora}`

    const urlRelativa = `${Environment.COMBO_BASE_URL}${listParam}&InternalProc=Modelo`

    const { data } = await Api.get<TModelo>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    })

    return data.ttMODELO
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao consultar os representantes')
  }
}

export const sessionModeloService = () => ({
  getModelosByCdLineCdMontadora
})
