import { Environment } from '../../../enviroments'
import { Api } from '../axios-config'
import { IListBanner } from '../Interfaces'

type TBanner = {
  ttBanner: IListBanner[]
}

const getAll = async (origem: String, language: string): Promise<IListBanner[] | Error> => {
  try {
    // const urlRelativa = `banners/SITE?apikey=${Environment.API_KEY}&idioma=${language}`;
    const urlRelativa = `${Environment.BANNER_BASE_URL}${origem},${language}`

    const { data } = await Api.get<TBanner>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    })


    return data.ttBanner
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao listar os banners')
  }
}

export const sessionBannerService = () => ({
  getAll
})
