import { useTranslation } from 'react-i18next';
import { IcoNavInstitucional } from '../../images';
import {
  MainFooter,
  MainHeader,
  MainLinhas,
  InstitucionalBody,
  MainFiltro,
  MainBreadcrumbs,
} from '../../shared/components';

export const Institucional: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <MainHeader />
      <MainFiltro />
      <MainBreadcrumbs title={t('aboutUs')} iconBreadCrumbs={IcoNavInstitucional} />
      <InstitucionalBody />
      <MainLinhas />
      <MainFooter />
    </>
  );
};
