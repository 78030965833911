import { HttpStatusCode } from 'axios'
import { Environment } from '../../../enviroments'
import { Api } from '../axios-config'
import { url } from 'inspector'

export interface IContato {
  nome?: string
  email?: string
  assunto: string
  mensagem: string
}

type TContato = {
  return: string
}

// Função auxiliar para construir os parâmetros da query string
const buildListParams = ({ nome = '', email = '', assunto = '', mensagem = '' }: IContato): string => {
  return [nome, email, assunto, mensagem].join(',')
}

const sendContatoForm = async (params: IContato): Promise<HttpStatusCode | Error> => {
  try {
    let listParam = buildListParams(params)

    let urlRelativa = `${Environment.CONTATO_BASE_URL}${listParam}`

    const response = await Api.get<TContato>(urlRelativa)

     if (response.data) {
      return HttpStatusCode.Ok
    }
    return new Error('Erro ao enviar o formulário')
  } catch (error) {
    console.error('Erro ao enviar o formulário:', error)
    return new Error((error as { message: string }).message || 'Erro ao enviar o formulário')
  }
}

export const sessionContatoService = () => ({
  sendContatoForm
})
