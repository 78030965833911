import {AxiosError} from 'axios'

export const errorInterceptor = (error: AxiosError) => {
    if(error.message==='Network Error'){
        return Promise.reject(new Error('Erro de conexão'))
    }
    if(error.response?.status===401){
        return Promise.reject(new Error('Problema nas credenciais de acesso à página'))
    }

    return Promise.reject(error)
}