/* eslint-disable jsx-a11y/anchor-is-valid */
import './MainFiltro.css'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IcoLinhaAgricola, IcoLinhaLeve, IcoLinhaPesada, IcoLinhaVoltex, IcoNavLancamentos } from '../../../images'

// import { ILineOptions } from '../../services/api/filtro/LineService'
import { IFamilyOptions, sessionFamiliaService } from '../../services/api/filtro/FamiliaService'
import { IMarcaOptions, sessionMontadoraService } from '../../services/api/filtro/MontadoraService'
import { IModeloOptions, sessionModeloService } from '../../services/api/filtro/ModeloService'
import { IListParams, sessionCatalogoService } from '../../services/api/catalogo/CatalogoService'
import { IListCatalogo } from '../../services/api/Interfaces'

import UseSessionCatalogo from '../../hooks/UseSessionCatalogo'
import { ISelectOptions } from '../../services/api/Interfaces/SelectOptions'
import { useToast } from '../../hooks/ToastContext'

export const tAll = 'All'

export const MainFiltro: React.FC = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const tCodigoIM = t('catalogFormSearchPlaceHolderIMCode')
  const tCdOriginal = t('catalogFormSearchPlaceHolderPartName')
  const tCdSimilares = t('catalogFormSearchPlaceHolderApplication')

  const tFamilia = t('catalogFormSearchFamily')
  const tMarca = t('catalogFormSearchCarManufacturer')
  const tModelo = t('catalogFormSearchVehicle')

  //UseState
  const [isLoading, setIsLoading] = useState(false)
  const [disabledFamilia, setDisabledFamilia] = useState(true)
  const [disabledMarca, setDisabledMarca] = useState(true)
  const [disabledModelo, setDisabledModelo] = useState(true)

  // const [linhas, setLinhas] = useState<ILineOptions[]>([])
  const [selectedLinha, setSelectedLinha] = useState(tAll)

  const [familias, setFamilias] = useState<IFamilyOptions[]>([])
  const [selectedFamilia, setSelectedFamilia] = useState(tAll)

  const [marcas, setMarcas] = useState<IMarcaOptions[]>([])
  const [selectedMarca, setSelectedMarca] = useState(tAll)

  const [modelos, setModelos] = useState<IModeloOptions[]>([])
  const [selectedModelo, setSelectedModelo] = useState(tAll)

  const [codigoIM, setCodigoIM] = useState('')
  const [cdOriginal, setCdOriginal] = useState('')
  const [cdSimilares, setCdSimilares] = useState('')

  const { setShowToast } = useToast()

  // Estado para armazenar o idioma anterior
  const [previousLanguage, setPreviousLanguage] = useState(i18n.language)

  //Services
  // const { getAllLines } = sessionLineService()
  const { getFamiliasByCdLine } = sessionFamiliaService()
  const { getMontadorasByFamilia } = sessionMontadoraService()
  const { getModelosByCdLineCdMontadora } = sessionModeloService()
  const { getFiltered, getLancamentos } = sessionCatalogoService()
  const { setSessionListaCatalogo } = UseSessionCatalogo()

  //Loading Linhas
  const todasAsLinhas: ISelectOptions[] = [
    { id: 0, label: t('catalogFormSearchLine'), value: t('All') },
    { id: 1, label: t('linePassengerCar'), value: t('linePassengerCarValue') },
    { id: 2, label: t('lineHeavyDuty'), value: t('lineHeavyDutyValue') },
    { id: 3, label: t('lineAgriculturalMachinery'), value: t('lineAgriculturalMachineryValue') },
    { id: 4, label: t('lineVoltex'), value: t('lineVoltexValue') }
  ]

  // Limpar busca e redirecionar ao mudar o idioma
  useEffect(() => {
    if (i18n.language !== previousLanguage) {
      // Limpar busca
      setCodigoIM('')
      setCdOriginal('')
      setCdSimilares('')
      setSelectedLinha(tAll)
      setSelectedFamilia(tAll)
      setSelectedMarca(tAll)
      setSelectedModelo(tAll)

      // Navegar para a página inicial
      navigate('/home')

      // Atualizar o idioma anterior
      setPreviousLanguage(i18n.language)
    }
  }, [i18n.language, previousLanguage, navigate]) // Executa ao mudar o idioma

  //Loading DropdownList Familia
  useEffect(() => {
    let language = i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase()

    if (selectedLinha !== tAll) {
      setIsLoading(true)
      getFamiliasByCdLine(selectedLinha, language).then((result) => {
        if (result instanceof Error) {
          alert(result.message)
        } else {
          if (result) {
            setFamilias(result)
            setDisabledFamilia(false)
            setIsLoading(false)
          }
        }
      })
    }
  }, [selectedLinha])

  //Loading DropdownList Montadora
  useEffect(() => {
    let language = i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase()

    if (selectedLinha !== tAll) {
      setIsLoading(true)
      getMontadorasByFamilia(selectedLinha, selectedFamilia).then((result) => {
        if (result instanceof Error) {
          alert(result.message)
        } else {
          if (result) {
            setMarcas(result)
            setDisabledMarca(false)
            setIsLoading(false)
          }
        }
      })
    }
  }, [selectedFamilia])

  //Loading DropdownList Veículos
  useEffect(() => {
    if (selectedLinha !== tAll && selectedFamilia !== tAll && selectedMarca !== tAll) {
      setIsLoading(true)

      getModelosByCdLineCdMontadora(selectedLinha, selectedFamilia, selectedMarca).then((result) => {
        if (result instanceof Error) {
          alert(result.message)
        } else if (result.length === 0) {
          setModelos([])
          setDisabledModelo(true)
        } else {
          setModelos(result)
          setDisabledModelo(false)
        }
        setIsLoading(false)
      })
    }
  }, [selectedMarca])

  const LoadSessionListaCatalogo = useCallback(
    (response: IListCatalogo[] | ((currVal: IListCatalogo[]) => IListCatalogo[])) => {
      setSessionListaCatalogo([])
      setSessionListaCatalogo(response)
      navigate('/catalogo')
    },
    [navigate, setSessionListaCatalogo]
  )

  // Parâmetros para a função getListParams
  interface IGetListParams {
    cBuscaGeral?: string
    cCdWeb?: string
    cCdOriginal?: string
    cCdSimilares?: string
    cLinha?: string
    cFamilia?: string
    cMarca?: string
    cModelo?: string
    cLancto?: string
    cIdioma?: string
  }

  // Função para obter listParams com parâmetros opcionais
  const getListParams = (params: IGetListParams): IListParams => {
    const language = i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase()
    return {
      cBuscaGeral: params.cBuscaGeral || '',
      cCdWeb: params.cCdWeb || '',
      cCdOriginal: params.cCdOriginal || '',
      cCdSimilares: params.cCdSimilares || '',
      cLinha: params.cLinha || '',
      cFamilia: params.cFamilia || '',
      cMarca: params.cMarca || '',
      cModelo: params.cModelo || '',
      cLancto: params.cLancto || '', // SIM = Valor padrão para buscas por lançamentos
      cIdioma: params.cIdioma || language
    }
  }

  //Loading Lancamentos
  const handleFilterByLancamentosSubmit = useCallback(
    (e: { preventDefault: () => void }, lancamento: string = 'SIM') => {
      e.preventDefault() // Só tenta prevenir o comportamento padrão se houver um evento

      setIsLoading(true)

      const listParams = getListParams({ cLancto: lancamento }) // Passando apenas o lançamento

      // Chamada à API com os parâmetros de listagem
      getLancamentos(listParams).then((response) => {
        if (response instanceof Error) {
          alert(response.message)
          return
        } else {
          LoadSessionListaCatalogo(response)
          setIsLoading(false)
        }
      })
    },
    []
  )

  const resetForm = () => {
    setCodigoIM('')
    setCdOriginal('')
    setCdSimilares('')
    setSelectedLinha(tAll)
    setSelectedFamilia(tAll)
    setSelectedMarca(tAll)
    setSelectedModelo(tAll)
    setFamilias([])
    setMarcas([])
    setModelos([])
    setDisabledFamilia(true)
    setDisabledMarca(true)
    setDisabledModelo(true)
  }

  const handleSubmit = (e: { preventDefault: () => void }) => {
    if (e) e.preventDefault()

    setIsLoading(true)

    const listParams: IListParams = {
      cBuscaGeral: '',
      cCdWeb: codigoIM,
      cCdOriginal: cdOriginal,
      cCdSimilares: cdSimilares,
      cLinha: selectedLinha !== tAll ? selectedLinha.toLowerCase() : '', // Verifique se não é o valor 'All'
      cFamilia: selectedFamilia !== tAll ? selectedFamilia : '', // Verifique se não é o valor 'All'
      cMarca: selectedMarca !== tAll ? selectedMarca : '',
      cModelo: selectedModelo !== tAll ? selectedModelo : '', // Verifique se não é o valor 'All'
      cLancto: '',
      cIdioma: i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase() // Define o idioma
    }
    getFiltered(listParams).then((response) => {
      if (response instanceof Error) {
        alert(response.message)
      } else if (response.length === 0) {
        setShowToast(true)
        resetForm() // Limpar e bloquear os campos
        setIsLoading(false)
      } else {
        LoadSessionListaCatalogo(response)
        resetForm() // Limpar e bloquear os campos
        setIsLoading(false)
      }
    })
  }

  const handleFilterByLineSubmit = (e: { preventDefault: () => void }, selectedLinha: string) => {
    e.preventDefault()
    setIsLoading(true)

    const listParams = getListParams({
      cLinha: selectedLinha !== tAll ? selectedLinha : '',
      cFamilia: selectedFamilia !== tAll ? selectedFamilia : '',
      cModelo: selectedModelo !== tAll ? selectedModelo : '',
      cIdioma: i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase()
    })

    getFiltered(listParams).then((response) => {
      if (response instanceof Error) {
        alert(response.message)
      } else {
        LoadSessionListaCatalogo(response)
        setIsLoading(false)
      }
    })
  }

  return (
    <>
      <section className='FiltroClass MarginTopBody'>
        <form onSubmit={handleSubmit}>
          <Container>
            <Row className='line-1'>
              {/* Codigo Industria Marilia */}
              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                <Form.Group className='mb-3' controlId=''>
                  <Form.Control
                    type='text'
                    placeholder={tCodigoIM}
                    value={codigoIM}
                    onChange={(e) => setCodigoIM(e.target.value)}
                  />
                </Form.Group>
              </Col>

              {/* Nome da Peça */}
              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                <Form.Group className='mb-3' controlId=''>
                  <Form.Control
                    type='text'
                    placeholder={tCdOriginal}
                    value={cdOriginal}
                    onChange={(e) => setCdOriginal(e.target.value)}
                  />
                </Form.Group>
              </Col>

              {/*Codigo Concorrencia */}
              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                <Form.Group className='mb-3' controlId=''>
                  <Form.Control
                    type='text'
                    placeholder={tCdSimilares}
                    value={cdSimilares}
                    onChange={(e) => setCdSimilares(e.target.value)}
                  />
                </Form.Group>
              </Col>

              {/* Combo Linha */}
              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                <Form.Select
                  aria-label='Linha'
                  name='selectLinha'
                  value={selectedLinha}
                  onChange={(e) => {
                    setSelectedLinha(e.target.value)
                  }}
                >
                  {/* <option value={tAll}>{tLinha}</option> */}
                  {todasAsLinhas &&
                    todasAsLinhas.map((linha) => (
                      <option key={linha.id} value={linha.value}>
                        {linha.label}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            </Row>
            <Row className='line-2'>
              {/* Combo Familia */}
              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                <Form.Select
                  aria-label='Familia'
                  name='selectFamilia'
                  disabled={disabledFamilia}
                  onChange={(e) => {
                    setSelectedFamilia(e.target.value)
                  }}
                >
                  <option value={tAll}>{tFamilia}</option>
                  {familias &&
                    familias.map((familia) => (
                      <option key={familia.cdFAMILIA} value={familia.dsFAMILIA}>
                        {familia.dsFAMILIA}
                      </option>
                    ))}
                </Form.Select>
              </Col>

              {/* Combo Montadora */}
              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                <Form.Select
                  aria-label='Motadora'
                  name='selectMontadora'
                  disabled={disabledMarca}
                  onChange={(e) => {
                    setSelectedMarca(e.target.value)
                  }}
                >
                  <option value={tAll}>{tMarca}</option>
                  {marcas &&
                    marcas.map((marca) => (
                      <option key={marca.cdMONTADORA} value={marca.dsMONTADORA}>
                        {marca.dsMONTADORA}
                      </option>
                    ))}
                </Form.Select>
              </Col>

              {/* Combo Modelo */}
              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                <Form.Select
                  aria-label='Modelo'
                  name='selectModelo'
                  disabled={disabledModelo}
                  onChange={(e) => setSelectedModelo(e.target.value)}
                >
                  <option value={tAll}>{tModelo}</option>
                  {modelos &&
                    modelos.map((modelo) => (
                      <option key={modelo.cdMODELO} value={modelo.dsMODELO}>
                        {modelo.dsMODELO}
                      </option>
                    ))}
                </Form.Select>
              </Col>

              {/* Combo Botão Pesquisar */}
              <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                {isLoading ? (
                  <Button variant='secondary BtFiltro Loading' type='submit'>
                    <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>{' '}
                    {t('loading')}
                  </Button>
                ) : (
                  <Button variant='secondary BtFiltro' type='submit'>
                    {t('catalogBtnSearch')}
                  </Button>
                )}
              </Col>
            </Row>

            <Row className='line-3'>
              <Col className='BoxLinha'>
                <div className='ItenLinha'>
                  <a
                    onClick={(e) => {
                      handleFilterByLineSubmit(e, t('linePassengerCar'))
                    }}
                  >
                    <img className='IcoLeve' src={IcoLinhaLeve} alt='' />
                    <span>{t('linePassengerCar')}</span>
                  </a>
                </div>
                <div className='ItenLinha'>
                  <a
                    onClick={(e) => {
                      handleFilterByLineSubmit(e, t('lineHeavyDuty'))
                    }}
                  >
                    <img className='IcoPesada' src={IcoLinhaPesada} alt='' />
                    <span>{t('lineHeavyDuty')}</span>
                  </a>
                </div>
                <div className='ItenLinha'>
                  <a
                    onClick={(e) => {
                      handleFilterByLineSubmit(e, t('lineAgriculturalMachinery'))
                    }}
                  >
                    <img className='IcoAgricola' src={IcoLinhaAgricola} alt='' />
                    <span>{t('lineAgriculturalMachinery')}</span>
                  </a>
                </div>
                <div className='ItenLinha'>
                  <a
                    onClick={(e) => {
                      handleFilterByLineSubmit(e, t('lineVoltex'))
                    }}
                  >
                    <img className='IcoVoltex' src={IcoLinhaVoltex} alt='' />
                    <span>{t('lineVoltex')}</span>
                  </a>
                </div>
                <div className='ItenLinha ItemLancamento'>
                  <a
                    onClick={(e) => {
                      handleFilterByLancamentosSubmit(e, 'SIM')
                    }}
                  >
                    <img className='IcoNavLancamentos' src={IcoNavLancamentos} alt='' />
                    <span>{t('newProducts')}</span>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
      </section>
      {/* End | FiltroClass */}
    </>
  )
}
