import { Carousel, Col, Container, Row } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { MainBody } from './MainBody';
import {
  ImgMariliaAntiga,
  ImgMariliaAtual,
  ImgMariliaEquipamento,
  ImgSliderInstitucional01,
  ImgSliderInstitucional02,
  ImgSliderInstitucional03,
  ImgSliderInstitucional04,
  ImgSliderInstitucional05,
  ImgSliderInstitucional06,
  ImgSliderInstitucional07,
  ImgSliderInstitucional08,
  ImgSliderInstitucional09,
  ImgSliderInstitucional10,
  ImgSliderInstitucional11,
  ImgSliderInstitucional12,
  ImgSliderInstitucional13,
  ImgSliderInstitucional14,
  ImgSliderInstitucional15,
  ImgSliderInstitucional16,
} from '../../../images';
import { useTranslation } from 'react-i18next';

import './InstitucionalBody.css';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const InstitucionalBody: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <MainBody pageClassName='InstitucionalClass'>
        <Container>
          <Row>
            <Col className='coluna-esquerda' xxl='8' xl='8' lg='8'>
              <Tabs className='mb-3' defaultActiveKey='theMarilia'>
                <Tab eventKey='theMarilia' title={t('ourHistory')}>
                  <div className='ContornoLinha'>
                    <h3>{t('theMarilia')}</h3>
                    <p>
                      {t('foundedLine1')}
                      <br />
                      <br />
                      <img src={ImgMariliaAntiga} alt='' />
                    </p>
                    <p>
                      {t('foundedLine2')}
                      <br />
                      <br />
                      <img src={ImgMariliaAtual} alt='' />
                    </p>
                    <div>
                      {t('foundedLine3')}
                      <br />
                      <br />
                      <img src={ImgMariliaEquipamento} alt='' />
                      <br />
                      <br />
                      {t('foundedLine4')}
                      <br />
                      <br />
                      <div className='divFlex'>
                        <p>{t('anyQuestionsAboutUs')}</p>&nbsp;&nbsp;{' '}
                        <a
                          className='whatsLink'
                          target='_blank'
                          rel='noopener noreferrer'
                          aria-label={t('contactUs')}
                          href='https://wa.me/+5511974006405'
                        >
                          <FontAwesomeIcon size='2x' icon={faWhatsapp} />
                          &nbsp;&nbsp; {t('contactUs')}
                        </a>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='codeConductEthicsTitle' title={t('codeConductEthicsTitle')}>
                  <div className='ContornoLinha'>
                    <h3>{t('codeConductEthicsTitle')}</h3>
                    <br />
                    <p>
                      {t('codeConductEthicsText')}
                      <br />
                      <br />
                      {t('codeConductEthicsFollowBellow')}
                    </p>
                    <br />
                    <h4>
                      <a
                        href='http://www.marilia-sa.com.br/downloads/ManualConduta.pdf'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {t('codeConductEthics')}
                      </a>{' '}
                    </h4>

                    {/* <h4>
                      <a
                        href='http://www.marilia-sa.com.br/downloads/ManualConduta.pdf'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {t('codeConductÉthicSuppliersAndServiceProviders')}
                      </a>
                    </h4> */}
                    <br />
                    <h4>
                      <a href='http://marilia-sa.masterprofile.com.br ' target='_blank' rel='noopener noreferrer'>
                        {t('ourReportingChannel')}
                      </a>
                    </h4>
                  </div>
                </Tab>
              </Tabs>
            </Col>

            <Col className='ContornoLinha coluna-direita'>
              <h3>{t('ourHistory')}</h3>
              <p>{t('textOurHistory')}</p>

              {/* Begin | Slider */}
              <Carousel>
                <Carousel.Item>
                  <img src={ImgSliderInstitucional01} alt='' />
                </Carousel.Item>

                <Carousel.Item>
                  <img src={ImgSliderInstitucional02} alt='' />
                </Carousel.Item>

                <Carousel.Item>
                  <img src={ImgSliderInstitucional03} alt='' />
                </Carousel.Item>

                <Carousel.Item>
                  <img src={ImgSliderInstitucional04} alt='' />
                </Carousel.Item>

                <Carousel.Item>
                  <img src={ImgSliderInstitucional05} alt='' />
                </Carousel.Item>

                <Carousel.Item>
                  <img src={ImgSliderInstitucional06} alt='' />
                </Carousel.Item>

                <Carousel.Item>
                  <img src={ImgSliderInstitucional07} alt='' />
                </Carousel.Item>

                <Carousel.Item>
                  <img src={ImgSliderInstitucional08} alt='' />
                </Carousel.Item>

                <Carousel.Item>
                  <img src={ImgSliderInstitucional09} alt='' />
                </Carousel.Item>

                <Carousel.Item>
                  <img src={ImgSliderInstitucional10} alt='' />
                </Carousel.Item>

                <Carousel.Item>
                  <img src={ImgSliderInstitucional11} alt='' />
                </Carousel.Item>

                <Carousel.Item>
                  <img src={ImgSliderInstitucional12} alt='' />
                </Carousel.Item>

                <Carousel.Item>
                  <img src={ImgSliderInstitucional13} alt='' />
                </Carousel.Item>

                <Carousel.Item>
                  <img src={ImgSliderInstitucional14} alt='' />
                </Carousel.Item>

                <Carousel.Item>
                  <img src={ImgSliderInstitucional15} alt='' />
                </Carousel.Item>

                <Carousel.Item>
                  <img src={ImgSliderInstitucional16} alt='' />
                </Carousel.Item>
              </Carousel>
              {/* End | Slider */}

              <hr className='linha-divisoria' />
              <h3>{t('ourVideo')}</h3>
              <p>{t('textOurVideo')}</p>

              <iframe
                className='video-institucional'
                src='https://www.youtube.com/embed/LYcQUE3pn1Q'
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              ></iframe>
            </Col>
          </Row>
        </Container>
      </MainBody>
    </>
  );
};
