import { Button } from 'react-bootstrap'
import { IListNoticia } from '../../services/api/noticias/NoticiasService'
import { ImagemNoticia } from './ImagemNoticia'
import { useState } from 'react'

interface INoticiasProps {
  noticias: IListNoticia[]
  noticiaSelecionada: (noticia: IListNoticia) => void
}

export const ListNoticia: React.FC<INoticiasProps> = ({ noticias, noticiaSelecionada }) => {
  const [noticiaSelected, setNoticiaSelected] = useState<IListNoticia>()

  const showDetail = (noticia: IListNoticia) => {
    noticiaSelecionada(noticia) // Enviar a notícia selecionada para o componente pai
    setNoticiaSelected(noticia)
  }

  return (
    <div className='box-scroll'>
      {noticias &&
        noticias.length > 0 &&
        noticias.map((noticia, index) => (
          <Button key={index} className='' variant='disable' onClick={() => showDetail(noticia)}>
            <div className='item-noticia' key={Number(noticia.cdWebNoticia)}>
              <div className='CapaListNoticia'>
                <span className='box-yellow-date'>{noticia && String(noticia.dtPublicacao)}</span>
                <ImagemNoticia noticiaImagem={noticia.Imagem && noticia.Imagem.length > 0 ? noticia.Imagem : []} />
                <span>
                  <h3 className='card-title'>{noticia.titulo}</h3>
                </span>
              </div>
            </div>
            <div className='linha-divisoria'></div>
          </Button>
        ))}
    </div>
  )
}
