import { MainFiltro, MainFooter, MainHeader, MainLinhas, RepresentantesBody } from '../../shared/components';

export const Representantes: React.FC = () => {
  return (
    <>
      <MainHeader />
      <MainFiltro />
      <RepresentantesBody />
      <MainLinhas />
      <MainFooter />
    </>
  );
};
