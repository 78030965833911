import { useTranslation } from 'react-i18next'
import { CatalogoBody, MainBanner, MainBreadcrumbs, MainFiltro, MainFooter, MainHeader } from '../../shared/components'
import { IcoNavCatalogo } from '../../images'

export const ListaCatalogo: React.FC = () => {
  const { t, i18n } = useTranslation()

  return (
    <>
      <MainHeader />
      <MainFiltro />
      <MainBreadcrumbs iconBreadCrumbs={IcoNavCatalogo} title={t('catalog')} />
      <CatalogoBody language={i18n.language} />

      <MainBanner />
      <MainFooter />
    </>
  )
}
