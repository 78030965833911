import { Environment } from '../../../enviroments'
import { Api } from '../axios-config'
import { IListagemRepresentantes } from '../Interfaces'

type TRepresentates = {
  ttRepJSON: IListagemRepresentantes[]
}

const getAll = async (unidadeFederativa?: string | ''): Promise<IListagemRepresentantes[] | Error> => {
  try {
    // const urlRelativa = `/representantes/${limit}/${offset}?apikey=${Environment.API_KEY}&localAtuacao=${local}`;

    let urlRelativa = `${Environment.REPRESENTANTE_BASE_URL}${unidadeFederativa}`

    // console.log('urlRelativaRepresentante:', urlRelativa)

    const { data } = await Api.get<TRepresentates>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    })

    return data.ttRepJSON
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao listar os representantes')
  }
}

const getById = async (cdRepresentante: Number): Promise<IListagemRepresentantes | Error> => {
  try {
    const urlRelativa = `/tt-rep?cd-representante=${cdRepresentante}`
    const { data } = await Api.get(urlRelativa)

    if (data) {
      return data
    }

    return new Error('Erro ao consultar o representante do estado selecionado.')
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao consultar os representantes')
  }
}

export const sessionRepresentanteService = () => ({
  getAll,
  getById
})
