import { Col, Container, Row } from 'react-bootstrap';

interface IMainBodyProps {
  children: React.ReactNode;
  pageClassName: string;
}

export const MainBody: React.FC<IMainBodyProps> = ({
  children,
  pageClassName,
}) => {
  return (
    <section className={pageClassName}>
      <Container>
        <Col>
          <Row>{children}</Row>
        </Col>
      </Container>
    </section>
  );
};
