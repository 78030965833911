import './ToastMessage.css'
import { Toast, ToastContainer } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { t } from 'i18next'

interface ToastMessageProps {
  showToast: boolean
  setShowToast: (value: boolean) => void
}

export const ToastMessage: React.FC<ToastMessageProps> = ({ showToast, setShowToast }) => {
  return (
    <>
      <ToastContainer position='top-center' className='p-3 custom-toast-container'>
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide>
          <Toast.Header className='custom-toast-header'>
            <FontAwesomeIcon icon={faExclamationTriangle} className={`alert-icon me-2`} />
            <strong className='me-auto'>{t('productCatalog')}</strong>
          </Toast.Header>
          <Toast.Body className='custom-toast-body'>{t('emptyList')}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  )
}
