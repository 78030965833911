import { ThumbNoLogo } from '../../../images'

export interface TLogoProps {
  dsEmpresa: string
  base64Image: string
}
export const LogoRepresentante: React.FC<TLogoProps> = ({ dsEmpresa, base64Image }) => {
  let logo: string = base64Image ? `data:image/png;base64,${base64Image}` : ThumbNoLogo

  return (
    <>
      <img src={logo} alt={dsEmpresa} />
    </>
  )
}
