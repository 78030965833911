import { IListNoticia } from '../../services/api/noticias/NoticiasService'
import { ImagemNoticia, TextoConteudoNoticia } from '../generic'

interface INoticiaContentProps {
  noticia: IListNoticia[]
}
export const NoticiasBodyDetail: React.FC<INoticiaContentProps> = ({ noticia }) => {
  // Verifique se o `noticia` está sendo passado corretamente ao componente
  if (!noticia) {
    console.log('Nenhuma notícia selecionada para exibir.')
    return <div>Carregando detalhes da notícia...</div>
  }
  console.log('NoticiasBodyDetail: ', noticia)
  return (
    <div key={Number(noticia[0].cdWebNoticia)}>
      <div className='CapaDetalheNoticia'>
        <ImagemNoticia noticiaImagem={noticia[0].Imagem && noticia[0].Imagem.length > 0 ? noticia[0].Imagem : []} />
        <br />
        <br />
        <span className='box-yellow-date'>{noticia[0].dtPublicacao && String(noticia[0].dtPublicacao)}</span>
        <br />
        <h3 className='card-title'>{noticia[0].titulo}</h3>
        <h5 className='card-subtitle mb-2 text-muted'>{noticia[0].subtitulo}</h5>
        <div className='item-detailnoticia'>
          {noticia[0].textoConteudo && (
            <TextoConteudoNoticia
              textoConteudo={noticia[0].textoConteudo}
              // noticiaLinks={noticia?.noticiaLinks}
            />
          )}
        </div>
      </div>
    </div>
  )
}
