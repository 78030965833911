import { useTranslation } from 'react-i18next';
import { MainBreadcrumbs, MainFiltro, MainFooter, MainHeader, MainLinhas, NoticiasBody } from '../../shared/components';
import { IcoTitleNoticias } from '../../images';

export const Noticias: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <MainHeader />
      <MainFiltro />
      <MainBreadcrumbs title={t('news')} iconBreadCrumbs={IcoTitleNoticias} />
      <NoticiasBody fromHomePage={false} />
      <MainLinhas />
      <MainFooter />
    </>
  );
};
