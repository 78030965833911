import './CatalogoBody.css'
import { SetStateAction, useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Pagination, Row } from 'react-bootstrap'
import LinesEllipsis from 'react-lines-ellipsis'
import Carousel from 'react-bootstrap/Carousel'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'

import { MainBody } from './MainBody'
import { Imagem } from '../generic/Imagem'

import { useDebounce } from '../../hooks'
import UseSessionCatalogo from '../../hooks/UseSessionCatalogo'
import { ParametrosPaginacao } from '../../utils/constants'
import { IListCatalogo } from '../../services/api/Interfaces'
import { IListParams, sessionCatalogoService } from '../../services/api/catalogo/CatalogoService'

interface ICatalogoProps {
  language: string
}

export const CatalogoBody: React.FC<ICatalogoProps> = ({ language }) => {
  const { t } = useTranslation()

  const { debounce } = useDebounce()
  const { getAll } = sessionCatalogoService()
  const { sessionListaCatalogo, setSessionListaCatalogo } = UseSessionCatalogo()
  const [produtos, setProdutos] = useState<IListCatalogo[]>([])
  const [textoAplicacaoProduto, setTextoAplicacaoProduto] = useState('')
  const [dsProdCatalogo, setDsProdCatalogo] = useState('')
  const [codigoOriginal, setCodigoOriginal] = useState('')
  const [codigoIM, setCodigoIM] = useState('')
  const [dsCatalogoLinha, setDsCatalogoLinha] = useState('')
  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const produtosPorPagina = ParametrosPaginacao.PRODUCT_LIMIT_SOURCE
  const totalPaginas = Math.ceil(produtos.length / produtosPorPagina)

  const handleClose = () => setShow(false)

  // Parâmetros para a função getListParams
  interface IGetListParams {
    cBuscaGeral?: string
    cCdWeb?: string
    cCdOriginal?: string
    cCdSimilares?: string
    cLinha?: string
    cFamilia?: string
    cMarca?: string
    cModelo?: string
    cLancto?: string
    cIdioma?: string
  }

  // Função para obter listParams com parâmetros opcionais
  const getListParams = (params: IGetListParams): IListParams => {
    const language = i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase()
    return {
      cBuscaGeral: params.cBuscaGeral || '',
      cCdWeb: params.cCdWeb || '',
      cCdOriginal: params.cCdOriginal || '',
      cCdSimilares: params.cCdSimilares || '',
      cLinha: params.cLinha || '',
      cFamilia: params.cFamilia || '',
      cMarca: params.cMarca || '',
      cModelo: params.cModelo || '',
      cLancto: params.cLancto || '', // SIM = Valor padrão para buscas por lançamentos
      cIdioma: params.cIdioma || language
    }
  }

  useEffect(() => {
    debounce(() => {
      if (sessionListaCatalogo && sessionListaCatalogo.length !== 0 && sessionCatalogoService !== undefined) {
        setIsLoading(true)
        setProdutos([])
        setProdutos(sessionListaCatalogo)
        setIsLoading(false)
      } else {
        setIsLoading(true)
        let lancamento: string = 'SIM'
        const listParams = getListParams({ cLancto: lancamento })
        getAll(listParams).then((result) => {
          if (result instanceof Error) {
            alert(result.message)
          } else {
            setIsLoading(false)
            setProdutos(result)
            setSessionListaCatalogo(result)
          }
        })
      }
    })
  }, [debounce, getAll, sessionListaCatalogo, setSessionListaCatalogo])

  // const { getFotosCatalogo } = sessionCatalogoService()
  // const [fotosCatalogo, setFotoCatalogo] = useState<IListFotosCatalogo[]>([])
  const [fotosCatalogo, setFotoCatalogo] = useState<string[]>([])

  const handleShowModal = (
    cdOriginal: string,
    cdIndustrial: string,
    textoAplicacao: string,
    dsProdCatalogo: string,
    cdWeb: string,
    dsCatalogoLinha: string
  ) => {
    setFotoCatalogo([])
    setShow(!show)

    const fotosProduto = produtos
      .filter((produto) => produto['cd-industrial'] === cdIndustrial)
      .flatMap((produto) => produto['lista-imagens'].split(',').map((url) => `${url.trim().replace(/\\/g, '/')}`))

    setFotoCatalogo(fotosProduto)
    setTextoAplicacaoProduto(textoAplicacao)
    setDsProdCatalogo(dsProdCatalogo)
    setCodigoIM(cdWeb)
    setCodigoOriginal(cdOriginal)
    setDsCatalogoLinha(dsCatalogoLinha)
    // })
  }

  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex: SetStateAction<number>) => {
    setIndex(selectedIndex)
  }

  // Defina o número de produtos exibidos por página
  const [paginaAtual, setPaginaAtual] = useState(1)

  const indiceUltimoProduto = paginaAtual * produtosPorPagina
  const indicePrimeiroProduto = indiceUltimoProduto - produtosPorPagina
  const produtosExibidos = produtos.slice(indicePrimeiroProduto, indiceUltimoProduto)

  const handleChangePagina = (novaPagina: number) => {
    setPaginaAtual(novaPagina)
  }

  const renderItensPaginacao = () => {
    const itensPaginacao: JSX.Element[] = []

    if (totalPaginas <= 5) {
      // Se o número total de páginas for menor ou igual a 5, mostrar todas as páginas
      for (let i = 1; i <= totalPaginas; i++) {
        itensPaginacao.push(
          <Pagination.Item key={i} active={i === paginaAtual} onClick={() => handleChangePagina(i)}>
            {i}
          </Pagination.Item>
        )
      }
    } else {
      // Caso contrário, usar ellipses para mostrar uma parte das páginas
      let firstEllipsis = false
      let lastEllipsis = false

      for (let i = 1; i <= totalPaginas; i++) {
        if (i === 1 || i === totalPaginas || (i >= paginaAtual - 1 && i <= paginaAtual + 1)) {
          itensPaginacao.push(
            <Pagination.Item key={i} active={i === paginaAtual} onClick={() => handleChangePagina(i)}>
              {i}
            </Pagination.Item>
          )
        } else {
          if (!firstEllipsis && i < paginaAtual) {
            itensPaginacao.push(<Pagination.Ellipsis key={`firstEllipsis`} />)
            firstEllipsis = true
          } else if (!lastEllipsis && i > paginaAtual) {
            itensPaginacao.push(<Pagination.Ellipsis key={`lastEllipsis`} />)
            lastEllipsis = true
          }
        }
      }
    }
    return itensPaginacao
  }

  return (
    <>
      <MainBody pageClassName='CatalogoClass'>
        <Container>
          <Row>
            <Col className='BoxLinha col'>
              <Pagination>{renderItensPaginacao()}</Pagination>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            {isLoading ? (
              <p>
                <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span> {t('loading')}
              </p>
            ) : (
              <Col>
                {produtosExibidos &&
                  produtosExibidos.map((produto, index) => {
                    return (
                      <Button
                        key={index}
                        className='BTCard'
                        variant='disable'
                        onClick={(_) =>
                          handleShowModal(
                            produto['cd-original'],
                            produto['cd-industrial'],
                            produto['texto-aplicacao'],
                            produto['ds-prod-catalogo'],
                            produto['cd-web'],
                            produto['lista-linha']
                          )
                        }
                      >
                        <Row className='noFlex'>
                          <Col className='NoSpace' xs lg='12'>
                            <div className='box-gray'>
                              <Imagem
                                ordem={produto.ordem}
                                listImage={produto['lista-imagens'].split(',')[0]}
                                dsProdCatalogo={produto['ds-prod-catalogo']}
                              />
                              <p>
                                <strong>{t('catalogFormSearchIMCode')}</strong> <span>{produto['cd-web']}</span>
                              </p>
                              <h3>{produto['ds-prod-catalogo']}</h3>
                              <LinesEllipsis
                                text={produto['texto-aplicacao']}
                                maxLine='2'
                                ellipsis='...'
                                trimRight
                                basedOn='letters'
                                className='TextoAplicacaoPruduto'
                              />
                              <p>
                                <strong>{t('catalogFormSearchLine')}:</strong> <span>{produto['lista-linha']}</span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Button>
                    )
                  })}
              </Col>
            )}
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className='BoxLinha col'>
              <Pagination>{renderItensPaginacao()}</Pagination>
            </Col>
          </Row>
        </Container>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div>{t('catalogFormSearchpartName')}:</div>
              <h6>{dsProdCatalogo}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Carousel activeIndex={index} onSelect={handleSelect}>
              {show &&
                fotosCatalogo &&
                fotosCatalogo.map((foto, index) => (
                  <Carousel.Item key={index} interval={1000} className='slide'>
                    {/* <img
                      src={`${foto ? `https://${foto}` : ThumbCatalogoNoImage}`}
                      alt={foto}
                      onClick={(e) => setShow(true)}
                    /> */}
                    <Imagem ordem={index} listImage={foto} dsProdCatalogo={dsProdCatalogo} />
                  </Carousel.Item>
                ))}
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <strong>{t('catalogFormSearchIMCode')}: </strong> <span>{codigoIM}</span>
              <hr />
            </div>
            <div>
              <strong>{t('catalogFormSearchOEM')}: </strong>
              <span>{codigoOriginal}</span>
            </div>
            <hr />
            <strong>{t('catalogFormSearchApplication')}: </strong>
            <p>{textoAplicacaoProduto}</p>
            <hr />
            <p>
              <strong>{t('catalogFormSearchLine')}: </strong>
              <span>{dsCatalogoLinha}</span>
            </p>
          </Modal.Footer>
        </Modal>
      </MainBody>
    </>
  )
}
