import { useTranslation } from 'react-i18next';
import {
  MainBreadcrumbs,
  MainFiltro,
  MainFooter,
  MainHeader,
  MainLinhas,
  QualidadeBody,
} from '../../shared/components';
import { IcoNavQualidade } from '../../images';

export const Qualidade: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <MainHeader />
      <MainFiltro />
      <MainBreadcrumbs title={t('quality')} iconBreadCrumbs={IcoNavQualidade} />
      <QualidadeBody />
      <MainLinhas />
      <MainFooter />
    </>
  );
};
