import { Environment } from '../../../enviroments'
import { Api } from '../axios-config'

export interface IMarcaOptions {
  cdMONTADORA: string
  dsMONTADORA: string
}
type TMarca = {
  ttMONTADORA: IMarcaOptions[]
}

const getMontadorasByFamilia = async (dsLinha?: string, dsFamilia?: string): Promise<IMarcaOptions[] | Error> => {
  try {
    const urlRelativa = `${Environment.COMBO_BASE_URL}${dsLinha},${dsFamilia}&InternalProc=Marca`

    const { data } = await Api.get<TMarca>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    })

    return data.ttMONTADORA
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao listar as famílias dos produtos')
  }
}

export const sessionMontadoraService = () => ({
  getMontadorasByFamilia
})
