import './RepresentantesBody.css'
import { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'

import { MainBody } from './MainBody'
import { IcoNavRepresentantes } from '../../../images'
import { UseUnidadesFederativas, useDebounce } from '../../hooks'

import { LogoRepresentante } from '../generic/LogoRepresentante'
import { sessionRepresentanteService } from '../../services/api/representates/RepresentantesService'
import { IListagemRepresentantes } from '../../services/api/Interfaces'

export const RepresentantesBody: React.FC = () => {
  const { t } = useTranslation()
  const { debounce } = useDebounce()
  const { getAll } = sessionRepresentanteService()
  const ufs = UseUnidadesFederativas()

  const [representantes, setRepresentantes] = useState<IListagemRepresentantes[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [stateName, setStateName] = useState('')
  const [unidadeFederativa, setUnidadeFederativa] = useState(stateName || 'SP')

  useEffect(() => {
    setIsLoading(true)

    debounce(() => {
      if (unidadeFederativa) {
        getAll(unidadeFederativa).then((result) => {
          setIsLoading(false)

          if (result instanceof Error) {
            alert(result.message)
            return
          } else {
            setRepresentantes(result)
          }
        })
      }
    })
  }, [debounce, getAll, stateName, unidadeFederativa])

  return (
    <>
      <MainBody pageClassName='RepresentantesClass'>
        <Container>
          <Row className='RowCards'>
            {/* <Row> */}
            <Col className='box-representantes'>
              <div className='box-title'>
                <h2>
                  <img src={IcoNavRepresentantes} alt='' />
                  <span>{t('representative')}</span>
                </h2>
              </div>
              <div className='combo-UF'>
                <Form.Select aria-label='Default select example' onChange={(e) => setUnidadeFederativa(e.target.value)}>
                  <option>Escolha o estado desejado...</option>
                  {ufs.map((uf) => (
                    <option key={uf.id} value={uf.value}>
                      {uf.label}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
            {/* </Row> */}
            <Row>
              {isLoading && <span> {t('loading')}</span>}
              {representantes.length >= 0 &&
                representantes
                  .sort((a, b) => (a.enderecoUF > b.enderecoUF ? 1 : -1))
                  .map((rep, index) => {
                    return (
                      <Col key={index} xxl='6' xl='6' lg='6'>
                        <Card>
                          <Card.Header as='h5'>{rep.enderecoUF}</Card.Header>
                          <Card.Body>
                            <Card.Title>{rep.dsEmpresa}</Card.Title>
                            <Card.Text>
                              <div className='imgLogoRepresentantes'>
                                <LogoRepresentante dsEmpresa={rep.dsEmpresa} base64Image={rep.base64imagem1} />
                              </div>
                              <div className='conteudoRepresentantes'>
                                <div className='txtRepresentantes txtEndereco'>{rep.endereco}</div>
                                <div className='txtRepresentantes'>
                                  <span>{rep.enderecoMunicipio}</span> / <span>{rep.enderecoUF}</span>
                                </div>
                                <div className='txtRepresentantes'>
                                  <span>CEP: {rep.cep}</span> - <span>{rep.enderecoPAIS}</span>
                                </div>
                                <div className='txtRepresentantes'>
                                  <a href='mailto:'>E-mail: {rep.email}</a>
                                </div>
                                <div className='txtRepresentantes'>{rep.fone ? `Tel(s): ${rep.fone}` : <br></br>}</div>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    )
                  })}
            </Row>
          </Row>
        </Container>
      </MainBody>
    </>
  )
}
