import { ThumbCatalogoNoImage } from '../../../images'

interface ImagemProps {
  ordem: number
  listImage: string
  dsProdCatalogo: string
}

export const Imagem: React.FC<ImagemProps> = ({ ordem, listImage, dsProdCatalogo }) => {
  return (
    <>
      <img
        key={ordem}
        src={`${listImage ? `https://${listImage}` : ThumbCatalogoNoImage}`}
        alt={dsProdCatalogo}
        loading='lazy'
      />
    </>
  )
}
