import '../body/HomeBody.css'
import '../body/CatalogoBody'
import { ThumbNoticia1 } from '../../../images'

export interface IFotoProps {
  noticiaImagem: string[]
}

export const ImagemNoticia: React.FC<IFotoProps> = ({ noticiaImagem }) => {
  return (
    <>
      {noticiaImagem && (
        <img
          className='ThumbNoticias'
          src={noticiaImagem[0] ? noticiaImagem[0] : ThumbNoticia1}
          alt={noticiaImagem[0]}
          loading='lazy'
        />
      )}
    </>
  )
}
