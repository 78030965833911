import { IListNoticia } from '../../services/api/noticias/NoticiasService'
import { ImagemNoticia, TextoConteudoNoticia } from '../generic'

interface INoticiasContentProps {
  noticias: IListNoticia[]
}
export const NoticiasBodyContent: React.FC<INoticiasContentProps> = ({ noticias }) => {
  return (
    <>
      {noticias.map((noticia) => (
        <div key={Number(noticia.cdWebNoticia)}>
          <div className='wrap-noticias'>
            <div className='CapaNoticia'>
              <span>
                <ImagemNoticia noticiaImagem={noticia.Imagem && noticia.Imagem.length > 0 ? noticia.Imagem : []} />
              </span>
            </div>
            <div className='item-noticia'>
              <h3 className='card-title'>
                <span className='box-yellow-date'>{noticia && String(noticia.dtPublicacao)}</span>
                {noticia && noticia.titulo}
              </h3>
              <h4 className='card-subtitle mb-2 text-muted'>{noticia && noticia.subtitulo}</h4>

              <TextoConteudoNoticia
                // textoConteudo={noticia?.textoConteudo.replaceAll('<br><br>', ' \n\n')}
                textoConteudo={noticia?.textoConteudo}
                // noticiaLinks={noticia?.LinkExterno}
              />
            </div>
          </div>
          <br />
          <div className='linha-divisoria'></div>
          <br />
        </div>
      ))}
    </>
  )
}
