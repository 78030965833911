import './ContatoBody.css'

import { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { MainBody } from './MainBody'
import { IContato, sessionContatoService } from '../../services/api/contato/ContatoService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

export const ContatoBody: React.FC = () => {
  const { t } = useTranslation()
  const [error, setError] = useState('')

  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    assunto: 'Comercial',
    mensagem: ''
  })

  const denuncia = 'Denúncia'
  const { sendContatoForm } = sessionContatoService()

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  // Parâmetros para a função getListParams
  interface IGetListParams {
    pNome?: string
    pEmail?: string
    pAssunto: string
    pMenssagem: string
  }

  // Função para obter listParams com parâmetros opcionais
  const getListParams = (params: IGetListParams): IContato => {
    return {
      nome: params.pNome || '',
      email: params.pEmail || '',
      assunto: params.pAssunto,
      mensagem: params.pMenssagem
    }
  }

  const sendForm = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setError('')

    // Validação dos campos obrigatórios
    if (formData.assunto !== denuncia) {
      if (!formData.nome) {
        setError('Nome é obrigatório.')
        return
      }

      if (!formData.email) {
        setError('E-mail é obrigatório.')
        return
      }
    }

    if (!formData.mensagem) {
      setError('Mensagem é obrigatória.')
      return
    }
    // Envia os dados usando o ContatoService
    sendContatoForm(formData).then((result) => {
      if (result instanceof Error) {
        alert(result.message)
      } else {
        alert('Formulário enviado com sucesso!')
        setFormData({
          nome: '',
          email: '',
          assunto: 'Comercial',
          mensagem: ''
        })
      }
    })
  }

  return (
    <MainBody pageClassName='ContatoClass'>
      <Container>
        <Row>
          <Col className='ContornoLinha coluna-esquerda' xxl='12' xl='12' lg='12'>
            <h3>{t('contactFormContactUs')}</h3>
            <p>{t('contactFormSubTitle')}</p>
            <Form onSubmit={sendForm}>
              <Form.Group className='mb-3' controlId='exampleName.ControlInput1'>
                <Form.Label>{t('contactFormName')}</Form.Label>
                <Form.Control
                  type='text'
                  name='nome'
                  value={formData.nome}
                  onChange={handleInputChange}
                  required={formData.assunto !== denuncia}
                  placeholder={t('contactFormPlaceholderName')}
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                <Form.Label>{t('contactFormEmail')}</Form.Label>
                <Form.Control
                  type='email'
                  placeholder={t('contactFormPlaceholderEmail')}
                  name='email'
                  value={formData.email}
                  onChange={handleInputChange}
                  required={formData.assunto !== denuncia}
                />
              </Form.Group>

              <Form.Select aria-label='Assunto' name='assunto' onChange={handleInputChange} required>
                <option>{t('contactFormSubject')}</option>
                <option value='Comercial'>{t('contactFormComercialSubject')}</option>
                <option value='Reclamação'>{t('contactFormComplaintSubject')}</option>
                <option value='Denúncia'>{t('contactFormDenouncementSubject')}</option>
                <option value='Sugestão'>{t('contactFormSuggestionSubject')}</option>
              </Form.Select>

              <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                <Form.Label>{t('contactFormMessage')}</Form.Label>
                <Form.Control
                  as='textarea'
                  name='mensagem'
                  value={formData.mensagem}
                  placeholder={t('contactFormPlaceholderMessage')}
                  rows={5}
                  required
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Button variant='warning' type='submit'>
                <FontAwesomeIcon icon={faEnvelope} />
                {t('contactFormBtnSend')}
              </Button>
            </Form>
            <br />
            <hr />
            <div className='ContatoClassPrivacyPolicy'>
              <h3>{t('contactPrivacyPolicyTitle')}</h3>
              <br />
              <br />
              <p>
                {t('contactPrivacyPolicyDescription1')}{' '}
                <a href='mailto:contato.seguro@marilia-sa.com.br'>contato.seguro@marilia-sa.com.br</a>
                <br />
              </p>
              <p>
                {t('contactPrivacyPolicyDescription2')}
                <br />
              </p>
              <p>
                {t('contactPrivacyPolicyDescription3')}{' '}
                <a
                  href='https://www.marilia-sa.com.br/Imagens/Downloads/Politica_de_Privacidade.pdf'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Política de Privacidade.
                </a>
                <br />
                <br />
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </MainBody>
  )
}
