import { Nav } from 'react-bootstrap'
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom'

interface IListItemLinkProps {
  to: string
  label?: string
  icon?: string
  className?: string
}

export const ListItemLink: React.FC<IListItemLinkProps> = ({ to, label, icon, className }) => {
  const resolvedPath = useResolvedPath(to)
  const match = useMatch({ path: resolvedPath.pathname, end: false })

  return (
    <li className='nav-item'>
      <Nav.Link
        className={className ? className : 'nav-link'}
        as={NavLink}
        to={to.startsWith('/') ? to : `/${to}`}
        active={!!match}
      >
        <img className='IcoNavMenu' src={icon} alt='' />
        <span>{label}</span>
      </Nav.Link>
    </li>
  )
}
