import { useEffect, useState } from 'react';
import { sessionUfService } from '../services/api/filtro/UfService';
import { ISelectOptions } from '../services/api/Interfaces/SelectOptions';

export const UseUnidadesFederativas = (): ISelectOptions[] => {
  const { getUf } = sessionUfService();
  const [uf, setUf] = useState<ISelectOptions[]>([]);

  useEffect(() => {
    getUf().then((result) => {
      if (result instanceof Error) {
        alert(result.message);
        return;
      } else {
        setUf(result);
      }
    });
  }, [getUf]);
  return uf;
};
