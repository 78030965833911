/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Container, Row } from 'react-bootstrap'
import './MainLinhas.css'
import { IcoLinhaAgricola, IcoLinhaLeve, IcoLinhaPesada, IcoLinhaVoltex, IcoTitleLinhas } from '../../../images'
import { useTranslation } from 'react-i18next'
import { IListParams, sessionCatalogoService } from '../../services/api/catalogo/CatalogoService'
import { IListCatalogo } from '../../services/api/Interfaces'
import UseSessionCatalogo from '../../hooks/UseSessionCatalogo'
import { useNavigate } from 'react-router-dom'
import { tAll } from '../filtro/MainFiltro'

export const MainLinhas: React.FC = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const { getFiltered } = sessionCatalogoService()
  const { setSessionListaCatalogo } = UseSessionCatalogo()

  const SetSessionListaCatalogo = (response: IListCatalogo[] | ((currVal: IListCatalogo[]) => IListCatalogo[])) => {
    setSessionListaCatalogo([])
    setSessionListaCatalogo(response)
    navigate(`/catalogo`)
  }

  const handleFilterByLineSubmit = (e: { preventDefault: () => void }, dsLinha: string) => {
    e.preventDefault()

    const listParams: IListParams = {
      cBuscaGeral: '',
      cCdWeb: '',
      cCdOriginal: '',
      cCdSimilares: '',
      cLinha: dsLinha !== tAll ? dsLinha : '',
      cFamilia: '',
      cMarca: '',
      cModelo: '',
      cLancto: '',
      cIdioma: i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase() // Define o idioma
    }

    getFiltered(listParams).then((response) => {
      if (response instanceof Error) {
        alert(response.message)
        return
      } else {
        SetSessionListaCatalogo(response)
      }
    })
  }

  return (
    <>
      {/* Begin | Box Linhas */}
      <section className='LinhasClass'>
        <Container className='container-linhas'>
          <Row className='RowTitle'>
            <Col>
              <h2>
                <img src={IcoTitleLinhas} alt='' />
                <span>{t('productLine')}</span>
              </h2>
            </Col>
          </Row>
          <Row className='RowItemLinhas'>
            <Col className='item-linhas'>
              <a
                onClick={(e) => {
                  handleFilterByLineSubmit(e, 'Linha Leve')
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  })
                }}
              >
                <img className='IcoLinhaLeve' src={IcoLinhaLeve} alt='' />
                <span>{t('linePassengerCar')}</span>
              </a>
            </Col>
            <Col className='item-linhas'>
              <a
                onClick={(e) => {
                  handleFilterByLineSubmit(e, 'Linha Pesada')
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  })
                }}
              >
                <img className='IcoLinhaPesada' src={IcoLinhaPesada} alt='' />
                <span>{t('lineHeavyDuty')}</span>
              </a>
            </Col>
            <Col className='item-linhas'>
              <a
                onClick={(e) => {
                  handleFilterByLineSubmit(e, 'Linha Agricola')
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  })
                }}
              >
                <img className='IcoLinhaAgricola' src={IcoLinhaAgricola} alt='' />
                <span>{t('lineAgriculturalMachinery')}</span>
              </a>
            </Col>
            <Col className='item-linhas'>
              <a
                onClick={(e) => {
                  handleFilterByLineSubmit(e, 'Linha Voltex')
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  })
                }}
              >
                <img className='IcoLinhaVoltex' src={IcoLinhaVoltex} alt='' />
                <span>{t('lineVoltex')}</span>
              </a>
            </Col>
          </Row>
        </Container>
      </section>
      {/* End | Box Linhas */}
    </>
  )
}
