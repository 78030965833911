import './NoticiasBody.css'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '../../hooks'
import { useEffect, useState } from 'react'
import { MainBody } from './MainBody'
import { Col, Container, Row } from 'react-bootstrap'

import { ListNoticia } from '../generic'
import { NoticiasBodyContent } from './NoticiasBodyContent'
import { NoticiasBodyDetail } from './NoticiasBodyDetail'
import { IListNoticia, IListParams, sessionNoticiasService } from '../../services/api/noticias/NoticiasService'
import { t } from 'i18next'

export interface INoticiasProps {
  fromHomePage: boolean
  noticiaSelected?: IListNoticia[]
  showDetail?: boolean
}

export const NoticiasBody: React.FC<INoticiasProps> = ({ fromHomePage, showDetail, noticiaSelected }) => {
  const { i18n } = useTranslation('noticias')
  const { debounce } = useDebounce()
  const { getAll, getById, getDestak } = sessionNoticiasService()

  const [isLoading, setIsLoading] = useState(true)
  const [noticias, setNoticias] = useState<IListNoticia[]>([])
  const [detalheNoticia, setDetalheNoticia] = useState<IListNoticia[]>()
  const [destaques, setDestaques] = useState<IListNoticia[]>([])

  const [language, setLanguage] = useState(() => (i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase()))

  // Parâmetros para a função getListParams
  interface IGetListParams {
    pCdWebNoticia?: string
    pBusca?: string
    pDesconsideraVigencia?: string
    pIdioma?: string
    pNoticiaEmDestaque?: string
  }

  // Função para obter listParams com parâmetros opcionais
  const getListParams = (params: IGetListParams): IListParams => {
    return {
      pCdWebNoticia: params.pCdWebNoticia || '',
      pBusca: params.pBusca || '',
      pDesconsideraVigencia: params.pDesconsideraVigencia || '',
      pIdioma: params.pIdioma || language,
      pNoticiaEmDestaque: params.pNoticiaEmDestaque || ''
    }
  }
  useEffect(() => {
    const novoIdioma = i18n.language === 'ptBR' ? 'PT' : i18n.language.toUpperCase()
    setLanguage(novoIdioma)

    // Resetar o detalhe da notícia para exibir notícias em destaque no novo idioma
    setNoticias([])
    setDestaques([])
    setDetalheNoticia(undefined) // Caso queira garantir que o detalhe é redefinido
    setIsLoading(true)

    // Carregar as notícias em destaque com o novo idioma
    debounce(() => {
      const destaqueParams = getListParams({ pIdioma: novoIdioma, pNoticiaEmDestaque: 'S' })
      getDestak(destaqueParams).then((resultDestaque) => {
        if (resultDestaque instanceof Error) {
          alert(resultDestaque.message)
          return
        } else {
          setDestaques(resultDestaque)
        }
      })

      // Carregar a lista completa de notícias no novo idioma
      const listParams = getListParams({ pIdioma: novoIdioma })
      getAll(listParams)
        .then((result) => {
          if (result instanceof Error) {
            alert(result.message)
          } else if (Array.isArray(result)) {
            // Verifica se result é um array antes de ordenar
            const resultOrdenado = result.sort(
              (a, b) => new Date(b.dtPublicacao).getTime() - new Date(a.dtPublicacao).getTime()
            )
            setNoticias(resultOrdenado)
          } else {
            setNoticias([]) // Caso result não seja um array, define noticias como array vazio
          }
        })
        .finally(() => setIsLoading(false))
    })
  }, [i18n.language])

  const handleNoticiaSelecionada = async (noticia: IListNoticia) => {
    let cdWebNoticia = noticia.cdWebNoticia
    const noticiasParams = getListParams({ pIdioma: language, pCdWebNoticia: cdWebNoticia })

    const detalhe = await getById(noticiasParams) // Busca os detalhes usando o cdWebNoticia
    if (detalhe instanceof Error) {
      alert(detalhe.message)
      return
    }
    setDetalheNoticia(detalhe) // Atualiza o estado com a notícia detalhada
  }

  return (
    <MainBody pageClassName='NoticiasClass'>
      <Container>
        <Row>
          <Col className='ContornoLinha coluna-esquerda' xxl='8' xl='8' lg='8'>
            {isLoading ? (
              <p>
                <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span> {t('loading')}
              </p>
            ) : detalheNoticia ? (
              <NoticiasBodyDetail noticia={detalheNoticia} />
            ) : (
              <NoticiasBodyContent
                noticias={
                  fromHomePage
                    ? destaques
                    : noticias
                        .slice(0, 2)
                        .sort((a, b) => new Date(b.dtPublicacao).getTime() - new Date(a.dtPublicacao).getTime())
                }
              />
            )}
          </Col>

          <Col className='ContornoLinha coluna-direita'>
            <ListNoticia noticias={noticias} noticiaSelecionada={handleNoticiaSelecionada} />
          </Col>
        </Row>
      </Container>
    </MainBody>
  )
}
