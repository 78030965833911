import { Carousel, Col, Container, Row } from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import {
  ImgSede,
  ImgSliderQualidadeIATF16949,
  ImgSliderQualidadeISO14001_2015,
  ImgSliderQualidadeISO9001_2015
} from '../../../images'
import { MainBody } from './MainBody'

import './QualidadeBody.css'
import { useTranslation } from 'react-i18next'

export const QualidadeBody: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <MainBody pageClassName='QualidadeClass'>
        <Container>
          <Row>
            <Col className='coluna-esquerda' xxl='8' xl='8' lg='8'>
              <Tabs className='mb-3' defaultActiveKey='qualityAndEnvironment'>
                <Tab eventKey='qualityAndEnvironment' title={t('integratedManagementSystem')}>
                  <div className='ContornoLinha'>
                    <h3>
                      {t('integratedManagementSystem')}
                      <span>({t('qualityAndEnvironment')})</span>
                    </h3>
                    <p>{t('textintegratedManagementSystem')}</p>
                    <hr className='linha-divisoria' />
                    <h4>{t('howDoYouComplyWithThisPolicy')}</h4>
                    <ul>
                      <li>{t('textFollowingTheDocumentation')}</li>
                      <li>{t('textKeepingTheWorkingEnvironmentTidy')}</li>
                      <li>{t('textAvoindingWates')}</li>
                      <li>{t('textSuggestingImprovements')}</li>
                    </ul>
                    <hr className='linha-divisoria' />
                    <h4>{t('whatIsThePurposeOfThisPolicy')}</h4>
                    <p>{t('textWhatIsThePurposeOfThisPolicy')}</p>
                    <hr className='linha-divisoria' />
                    <h4>{t('ourMission')}</h4>
                    <p>{t('textOurMission')}</p>
                    <hr className='linha-divisoria' />
                    <h4>{t('ourVision')}</h4>
                    <p>{t('textOurVision')}</p>
                    <hr className='linha-divisoria' />
                    <h4>{t('ourValues')}</h4>
                    <ul>
                      <li>{t('textOurValuesActWithHonestyAndTrustworthiness')}.</li>
                      <li>{t('textOurValuesHonorTheCommitmentsMade')}</li>
                      <li>{t('textOurValuesPracticeThePrincipleOfEquity')}</li>
                    </ul>
                  </div>
                </Tab>
                <Tab eventKey='productsWarrantyPolicy' title={t('productsWarrantyPolicy')}>
                  <div className='ContornoLinha'>
                    <h3>{t('productsWarrantyPolicy')}</h3>
                    <p>
                      {t('txtProductWarrantyPolicy')}
                      <br />
                      {t('txtProductWarrantyPolicyItemsExcluded')}
                    </p>
                    <hr className='linha-divisoria' />
                    <h4>{t('theWarrantyDoesNotCover')}</h4>
                    <ul>
                      <li>{t('textPartsThatWearOutNaturallyWithRegularUse')}</li>
                      <li>{t('textInfiltrationOfLiquids')}</li>
                      <li>{t('textPhysicalDamage')}</li>
                      <li>{t('textDefectsOrDamagesTests')}</li>
                      <li>{t('textElectricalAndMechanicalDefects')}</li>
                      <li>{t('textDamagesThatWereNotFoundAtTheTimeOfAcquisition')}</li>
                      <li>{t('textDefectsDemagesCausedByNaturalFenom')}</li>
                      <li>{t('textProductViolated')}</li>
                      <li>{t('textProductsTested')}</li>
                      <li>{t('textProductsThatHaveHadTheSerialNumberRemoved')}</li>
                      {t('textDefectsDemagesCausedByEletricDescharge') ? (
                        <li>{t('textDefectsDemagesCausedByEletricDescharge')}</li>
                      ) : (
                        ''
                      )}
                      {t('textDefectNaturalAgents') ? <li>{t('textDefectNaturalAgents')}</li> : ''}
                    </ul>
                    <hr className='linha-divisoria' />
                    <h4>{t('coverageAndWarrantyPeriodForTheBrazilianMarket')}</h4>
                    <p>
                      {t('textCoverageAndWarrantyPeriodoForTheBrazilianMarketLine1')}
                      <br />
                      <br />
                      {t('textCoverageAndWarrantyPeriodoForTheBrazilianMarketLine2')}
                      <br />
                      <br />
                      {t('textCoverageAndWarrantyPeriodoForTheBrazilianMarketLine3')}
                      <br />
                      <br />
                      {t('textCoverageAndWarrantyPeriodoForTheBrazilianMarketGeneral')}
                    </p>

                    <p>
                      <a
                        href='https://www.marilia-sa.com.br/Imagens/Downloads/Marilia-EnvioGarantia.pdf'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {t('textWarrantyTaxProcedure')}
                      </a>
                    </p>
                    <hr className='linha-divisoria' />

                    <h4>{t('textForWarrantyContactUs')}</h4>
                    <p>
                      Tel.: +55 11 2842-6113
                      <br />
                      Email: <a href='mailto:faleconosco@marilia-sa.com.br'>garantia@marilia-sa.com.br</a>
                    </p>
                    <hr className='linha-divisoria' />
                    <h4>{t('ifYouHaveAnyQuestionQuality')}</h4>
                    <p>
                      Tel.: +55 11 2842 6100
                      <br />
                      WhatsApp +55 11 97400-6405
                      <br />
                      Email: <a href='mailto:faleconosco@marilia-sa.com.br'>faleconosco@marilia-sa.com.br</a>
                    </p>
                  </div>
                </Tab>
              </Tabs>
            </Col>

            <Col className='ContornoLinha coluna-direita'>
              <img src={ImgSede} alt='' />
              <hr className='linha-divisoria' />
              <h3>{t('tqmTotalQualityManagement')}</h3>

              {/* Begin | Slider */}
              <Carousel>
                <Carousel.Item>
                  <a
                    href={'https://www.marilia-sa.com.br/Imagens/Catalogos/ISO_14001.jpg'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={'https://www.marilia-sa.com.br/Imagens/Catalogos/ISO_14001.jpg'} alt='' />
                  </a>
                </Carousel.Item>

                <Carousel.Item>
                  <a
                    href={'https://www.marilia-sa.com.br/Imagens/Catalogos/IATF_16949.jpg'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={'https://www.marilia-sa.com.br/Imagens/Catalogos/IATF_16949.jpg'} alt='' />
                  </a>
                </Carousel.Item>

                <Carousel.Item>
                  <a
                    href={'https://www.marilia-sa.com.br/Imagens/Catalogos/ISO_9001.jpg'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={'https://www.marilia-sa.com.br/Imagens/Catalogos/ISO_9001.jpg'} alt='' />
                  </a>
                </Carousel.Item>
              </Carousel>
              {/* End | Slider */}
            </Col>
          </Row>
        </Container>
      </MainBody>
    </>
  )
}
