import React, { createContext, useContext, useState } from 'react'
import { ToastMessage } from '../components/generic/ToastMessage'

interface ToastContextType {
  showToast: boolean
  setShowToast: (show: boolean) => void
}

const ToastContext = createContext<ToastContextType | undefined>(undefined)

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [showToast, setShowToast] = useState(false)

  return (
    <ToastContext.Provider value={{ showToast, setShowToast }}>
      {children}
      <ToastMessage showToast={showToast} setShowToast={setShowToast} />
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  const context = useContext(ToastContext)
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}
