import { Environment } from '../../../enviroments'
import { Api } from '../axios-config'
import { IListFotosCatalogo, IListCatalogo } from '../Interfaces'

type TFotoProduto = {
  produtoFotos: IListFotosCatalogo[]
}

type TProduto = {
  'tt-produto': IListCatalogo[]
}

export interface IListParams {
  cBuscaGeral: string | ''
  cCdWeb: string | ''
  cCdOriginal: string | ''
  cCdSimilares: string | ''
  cLinha: string | ''
  cFamilia: string | ''
  cMarca: string | ''
  cModelo: string | ''
  cLancto: string | ''
  cIdioma: string | 'PT'
}

// Função auxiliar para construir os parâmetros da query string
const buildListParams = ({
  cBuscaGeral = '',
  cCdWeb = '',
  cCdOriginal = '',
  cCdSimilares = '',
  cLinha = '',
  cFamilia = '',
  cMarca = '',
  cModelo = '',
  cLancto = '',
  cIdioma = 'PT' // Defina um valor padrão apropriado para idioma
}: IListParams): string => {
  return [cBuscaGeral, cCdWeb, cCdOriginal, cCdSimilares, cLinha, cFamilia, cMarca, cModelo, cLancto, cIdioma].join(',')
}

const getGeneralSearch = async (params: IListParams): Promise<IListCatalogo[] | Error> => {
  try {
    let listParam = buildListParams(params)

    const urlRelativa = `${Environment.GENERAL_BASE_URL}${listParam}`
    const { data } = await Api.get<TProduto>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    })

    return data['tt-produto']
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao listar os produtos')
  }
}

const getAll = async (params: IListParams): Promise<IListCatalogo[] | Error> => {
  try {
    let listParam = buildListParams(params)

    let urlRelativa = `${Environment.GENERAL_BASE_URL}${listParam}`

    // console.log('urlRelativa:', urlRelativa)
    const { data } = await Api.get<TProduto>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    })

    return data['tt-produto']
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao listar os produtos')
  }
}

const getLancamentos = async (params: IListParams): Promise<IListCatalogo[] | Error> => {
  try {
    let listParam = buildListParams(params)

    const urlRelativa = `${Environment.GENERAL_BASE_URL}${listParam}`

    const { data } = await Api.get<TProduto>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    })

    return data['tt-produto']
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao listar os produtos')
  }
}

const getFiltered = async (params: IListParams): Promise<IListCatalogo[] | Error> => {
  try {
    let listParam = buildListParams(params)

    let urlRelativa = `${Environment.GENERAL_BASE_URL}${listParam}`

    // console.log('urlRelativa. ', urlRelativa)

    const { data } = await Api.get<TProduto>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    })

    return data['tt-produto']
    //}
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao listar os produtos')
  }
}

const getFilteredByLine = async (params: IListParams): Promise<IListCatalogo[] | Error> => {
  try {
    let listParam = buildListParams(params)

    let urlRelativa = `${Environment.GENERAL_BASE_URL}${listParam}`

    const { data } = await Api.get<TProduto>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    })
    return data['tt-produto']
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao listar os produtos')
  }
}

const getFotosCatalogo = async (cdIndustrial: string): Promise<IListFotosCatalogo[] | Error> => {
  try {
    const urlRelativa = `${Environment.BASE_URL}produtoFotos/${cdIndustrial}?apikey=${Environment.API_KEY}`

    const { data } = await Api.get<TFotoProduto>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    })
    return data.produtoFotos
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao obter foto do produto')
  }
}

const getById = async (cdOriginal: Number): Promise<IListCatalogo | Error> => {
  try {
    const urlRelativa = `/tt-produto?cdOriginal=${cdOriginal}`

    const { data } = await Api.get(urlRelativa)

    if (data) {
      return data
    }

    return new Error('Erro ao consultar o produto.')
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao consultar o produto')
  }
}

export const sessionCatalogoService = () => ({
  getGeneralSearch,
  getAll,
  getLancamentos,
  getFiltered,
  getFilteredByLine,
  getFotosCatalogo,
  getById
})
