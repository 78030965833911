export const Environment = {
  LIMITE_DE_LINHAS: 10,
  INPUT_DE_BUSCA: 'O que procura',
  LISTAGEM_VAZIA_PT: 'Nenhum registro foi encontrado!',
  LISTAGEM_VAZIA_ES: '¡No se encontraron registros!',
  LISTAGEM_VAZIA_EN: 'No records found!',

  // BASE_URL: 'http://localhost:3334/',

  BASE_URL: 'https://meli.marilia-sa.com.br:8091/newera/',
  API_KEY: '970df65621bcd5dd8c9d56022ac7ebed41ab328be653e793de4ae21a155f0039',

  GENERAL_BASE_URL:
    'https://app.marilia-sa.com.br:10002/PasoeRestFull/restfull/proc/calloutput?Token=q7ZVVv3t1LYn1tdG2hyWvmhCRzgMSm&UrlServer=localhost:5090&ServerApp=appproducao&NameProc=web11/GERA_JSON_ITENS_v2.p&InternalProc=GERA_JSON&ListParam=',

  COMBO_BASE_URL:
    'https://app.marilia-sa.com.br:10002/PasoeRestFull/restfull/proc/calloutput?Token=q7ZVVv3t1LYn1tdG2hyWvmhCRzgMSm&UrlServer=localhost:5090&ServerApp=appproducao&NameProc=web11/API_RETORNA_TABELAS_LINHA_FAMILIA_MARCA_MODELO.p&ListParam=',

  BANNER_BASE_URL:
    'https://app.marilia-sa.com.br:10002/PasoeRestFull/restfull/proc/calloutput?Token=q7ZVVv3t1LYn1tdG2hyWvmhCRzgMSm&UrlServer=localhost:5090&ServerApp=appproducao&NameProc=web11/API_RETORNA_BANNERS.p&InternalProc=GERA_JSON&ListParam=',

  REPRESENTANTE_BASE_URL:
    'https://app.marilia-sa.com.br:10002/PasoeRestFull/restfull/proc/calloutput?Token=q7ZVVv3t1LYn1tdG2hyWvmhCRzgMSm&UrlServer=localhost:5090&ServerApp=appproducao&NameProc=web11/API_retorna_REPRESENTANTES.p&InternalProc=&ListParam=',

  NOTICIAS_BASE_URL:
    'https://app.marilia-sa.com.br:10002/PasoeRestFull/restfull/proc/calloutput?Token=q7ZVVv3t1LYn1tdG2hyWvmhCRzgMSm&UrlServer=localhost:5090&ServerApp=appproducao&NameProc=web11/API_RETORNA_NOTICIAS.p&InternalProc=&ListParam=',

  CONTATO_BASE_URL:
    'https://app.marilia-sa.com.br:10002/PasoeRestFull/restfull/proc/calloutput?Token=q7ZVVv3t1LYn1tdG2hyWvmhCRzgMSm&UrlServer=localhost:5090&ServerApp=appproducao&NameProc=web11/API_GRAVA_CONTATO.p&InternalProc=&ListParam='

  // https://app.marilia-sa.com.br:10002/PasoeRestFull/restfull/proc/calloutput?Token=q7ZVVv3t1LYn1tdG2hyWvmhCRzgMSm&UrlServer=localhost:5090&ServerApp=appproducao&NameProc=web11/API_RETORNA_TABELAS_LINHA_FAMILIA_MARCA_MODELO.p&ListParam=VOLTEX&InternalProc=FAMILIA

  // https://app.marilia-sa.com.br:10002/PasoeRestFull/restfull/proc/calloutput?Token=q7ZVVv3t1LYn1tdG2hyWvmhCRzgMSm&UrlServer=localhost:5090&ServerApp=appproducao&NameProc=web11/API_RETORNA_TABELAS_LINHA_FAMILIA_MARCA_MODELO.p&InternalProc=MODELO&ListParam=LEVE,Acendedor

  //<geral>,<cdweb ou cdoriginal>,<nome>,<cd similar>
}
