import { Environment } from '../../../enviroments'
import { Api } from '../axios-config'

export interface IListNoticia {
  cdWebNoticia: string
  dtPublicacao: string
  dtExpiracao: string
  titulo: string
  subtitulo: string
  textoConteudo: string
  destaque: string
  idioma: string
  Imagem: string[]
  LinkExterno: string[]
  LinkdsLegenda: string[]
}

type TNoticias = {
  ttNoticiaJSON: IListNoticia[]
}

export interface IListParams {
  pCdWebNoticia: string | ''
  pBusca: string | ''
  pDesconsideraVigencia: string | ''
  pIdioma: string | ''
  pNoticiaEmDestaque: string | ''
}

// Função auxiliar para construir os parâmetros da query string
const buildListParams = ({
  pCdWebNoticia = '',
  pBusca = '',
  pDesconsideraVigencia = '',
  pIdioma = 'PT', // Defina um valor padrão apropriado para idioma,
  pNoticiaEmDestaque = ''
}: IListParams): string => {
  return [pCdWebNoticia, pBusca, pDesconsideraVigencia, pIdioma, pNoticiaEmDestaque].join(',')
}

const getAll = async (params: IListParams): Promise<IListNoticia[] | Error> => {
  try {
    let listParam = buildListParams(params)

    let urlRelativa = `${Environment.NOTICIAS_BASE_URL}${listParam}`

    const { data } = await Api.get<TNoticias>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    })

    // console.log('noticasJSON:', data.ttNoticiaJSON)
    return data.ttNoticiaJSON
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao listar as notícias')
  }
}

const getDestak = async (params: IListParams): Promise<IListNoticia[] | Error> => {
  try {
    let listParam = buildListParams(params)

    let urlRelativa = `${Environment.NOTICIAS_BASE_URL}${listParam}`

    const { data } = await Api.get<TNoticias>(urlRelativa, {
      headers: {
        Accept: 'application/json'
      }
    })
    return data.ttNoticiaJSON
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao listar as notícias')
  }
}

const getById = async (params: IListParams): Promise<IListNoticia[] | Error> => {
  try {
    let listParam = buildListParams(params)

    let urlRelativa = `${Environment.NOTICIAS_BASE_URL}${listParam}`
    const { data } = await Api.get<TNoticias>(urlRelativa)

    return data.ttNoticiaJSON
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message || 'Erro ao consultar a notícia')
  }
}

export const sessionNoticiasService = () => ({
  getAll,
  getDestak,
  getById
})
